<template>
  <b-row>

    <!--    File-->
    <b-col md="12">
      <field-file
          name="document"
          :isRequired="true"
          :singleFile.sync="vatDeclaration.document"
          documentType="vatDeclaration"
      />
    </b-col>

    <!--    Month-->
    <b-col md="6">
      <field-date
          name="month"
          :rules="'required'"
          :model.sync="vatDeclaration.month"
          :isRequired="true"
          :onlyMonth="true"
      />
    </b-col>

    <!--    Payment date-->
    <b-col md="6">
      <field-date
          name="paymentDate"
          :rules="'required'"
          :model.sync="vatDeclaration.paymentDate"
          :isRequired="true"
      />
    </b-col>

    <!-- Payment method -->
    <b-col md="6">
      <field-select
          :model.sync="vatDeclaration.paymentMethod"
          :name="'paymentMethod'"
          :options="autocompletePaymentMethods"
          :label="'name'"
          :isRequired="true"
          :clearable="false"
      />
    </b-col>

    <!-- Bank account -->
    <b-col md="6">
      <field-select
          :name="capitalize($t('accountInvolved'))"
          :model.sync="vatDeclaration.bankAccount"
          :options="autocompleteBankAccounts"
          :is-required="true"
      />
    </b-col>

    <!--    Debit -->
    <b-col md="6">
      <field-input
          name="debit"
          :rules="'decimal'"
          :model.sync="vatDeclaration.debit"
      />
    </b-col>

    <!--    Credit -->
    <b-col md="6">
      <field-input
          name="credit"
          :rules="'decimal'"
          :model.sync="vatDeclaration.credit"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {capitalize} from '../../utils/filter'

import FieldInput from '../input/Input.vue'
import FieldDate from '../input/Date.vue'
import FieldSelect from '../input/Select2.vue'
import FieldFile from '../input/File.vue'
import useAPI from '../../utils/useAPI'

export default {
  components: {
    FieldInput,
    FieldDate,
    FieldSelect,
    FieldFile
  },
  props: {
    vatDeclaration: {
      type: Object,
      default: () => {}
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteBankAccounts,
      autocompletePaymentMethods,
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      autocompleteBankAccounts,
      autocompletePaymentMethods,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>