<template>
  <div>

    <base-template
        title="VatDeclarations"
        itemView="list"
        :text-button-add="$t('Add')"
        :columns="columns"
        :filters="filters"
        :items="vatDeclarations"
        :context="vatDeclarationsContext"
        :isActionColumnEnabled="true"
        :open-view-window.sync="openViewWindow"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :allow-add-payment="true"
        :is-button-add-payment-disabled="!selectedItems.length"
        :display-custom-fields="displayCustomFields&&(allowAdd||allowAddByUpload)"
        selectable="multi"

        @fetch="$emit('reloadVatDeclarations', $event)"
        @rowSelected="rowSelected"
        @selectItem="showDetails"
        @addItem="vatDeclarationsModalShow=true"
        @addItemByUpload="vatDeclarationsByUploadModalShow = true"
        @addPayment="addPayment(selectedItems)"
    >
<!--      <template #bottomTitle>-->
<!--        <i v-if="vatDeclarationsContext.custom_fields && displayCustomFields && !allowAdd && !allowAddByUpload">-->
<!--          {{ currency(vatDeclarationsContext.custom_fields.excludingExpensesAndReimbursements) }}-->
<!--          {{ $t('excludingExpensesAndReimbursements') }}-->
<!--        </i>-->
<!--      </template>-->

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <vat-declaration-card
            :vatDeclaration="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="allowPayment"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadVatDeclaration(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              @click.native.stop="addPayment([item])"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="item._balance == 0"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="editVatDeclaration(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteVatDeclarationAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <vatDeclaration-card
            :vatDeclaration="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4>{{ selectedItems[0].month |moment('LL') }}</h4>

          <div>
            <!--          Download-->
            <button-download
                @click.native.stop="downloadVatDeclaration(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Download')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="editVatDeclaration(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <vat-declaration-view
            class="invoice-preview"
            :vat-declaration="selectedItems[0]"
        />


      </template>
    </base-template>

    <!-- modal vatDeclaration by upload-->
    <modal-upload-vat-declaration
        :singleFile.sync="newVatDeclarationFile"
        :documentType="'vatDeclaration'"
        :title="capitalize($tc('vatDeclaration'))"
        :isOpen.sync="vatDeclarationsByUploadModalShow"

    />

    <!-- modal vatDeclarations-->
    <modal-vat-declaration
        :vatDeclaration.sync="currentVatDeclaration"
        :title="vatDeclarationModalTitle"
        :isOpen.sync="vatDeclarationsModalShow"
        @submitValidated="submitValidatedVatDeclarationsLocal(currentVatDeclaration)"
    />

    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"

        @submitValidated="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'
import { useVatDeclarations } from '../../views/accounting2/vatDeclaration/useVatDeclarations'
import { usePayments } from '../../views/accounting2/usePayment'

import BaseTemplate from '../base/Base'
import VatDeclarationCard from '../card/VatDeclaration.vue'
import ButtonView from '../button/View'
import ButtonDownload from '../button/Download'
import ButtonEdit from '../button/Edit.vue'
import ButtonRemove from '../button/Remove.vue'
import ButtonClose from '../button/Close'
import VatDeclarationView from './VatDeclarationView.vue'
import ModalUploadVatDeclaration from '../prompt/Upload'
import ModalVatDeclaration from '../prompt/VatDeclaration'
import ModalPayment from '../../components/prompt/Payment2'
import ButtonPayment from '../../components/button/Payment'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import store from '../../store'
import moment from 'moment'

export default {
  components: {
    BaseTemplate,
    VatDeclarationCard,
    ButtonView,
    ButtonDownload,
    ButtonEdit,
    ButtonRemove,
    ButtonClose,
    VatDeclarationView,
    ModalUploadVatDeclaration,
    ModalVatDeclaration,
    ModalPayment,
    ButtonPayment
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: true
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('month')),
        key: 'month',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('paymentDate')),
        key: 'paymentDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.tc('bankAccount')),
        key: 'bankAccount',
        sortable: true,
        cell: {
          useDisplay: true
        }
      },
      {
        display: capitalize(i18n.tc('paymentMethod')),
        key: 'paymentMethod',
        sortable: true,
        cell: {
          useName: true,
          translate: true,
        }
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          translate: true,
          useBadge: true,
          type: 'paymentStatus',
          // center: true
        }
      },
      {
        display: capitalize(i18n.tc('debit')),
        key: 'debit',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },
      {
        display: capitalize(i18n.tc('credit')),
        key: 'credit',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },

    ])
    const filters = ref([])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const newVatDeclarationFile = ref(null)
    const vatDeclarationsByUploadModalShow = ref(false)
    const currentVatDeclaration = ref({})
    const currentPayment = ref({})
    const vatDeclarationModalTitle = ref(i18n.t('NewVatDeclaration'))
    const vatDeclarationsModalShow = ref(false)
    const paymentModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { vatDeclarations, vatDeclarationsContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newVatDeclarationFile, (val) => {
      if (val != null) {
        recognizeVatDeclaration(val)
            .then(response => {
              // console.log(response.data)
              if (response.data.paymentMethod) {
                response.data.paymentMethod.name = i18n.t(response.data.paymentMethod.name)
              }

              vatDeclarationsByUploadModalShow.value = false
              currentVatDeclaration.value = response.data
              vatDeclarationsModalShow.value = true
            })
      }

    })

    watch(vatDeclarationsModalShow, val => {
      if (val == false) {
        currentVatDeclaration.value = {}
        vatDeclarationModalTitle.value = i18n.t('NewVatDeclaration')
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      recognizeVatDeclaration,
      downloadVatDeclaration,
      removeVatDeclaration,
      submitValidatedVatDeclaration
    } = useVatDeclarations()


    const { submitValidatedPayment } = usePayments()

    const showDetails = (vatDeclaration) => {
      if (vatDeclaration != null) {
        store.dispatch('vatDeclaration/getVatDeclaration', vatDeclaration.id)
            .then(response => {
              // console.log(response)
              // selectedItems.value = vatDeclaration
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const editVatDeclaration = (vatDeclaration) => {
      vatDeclarationModalTitle.value = i18n.t('EditVatDeclaration')

      store.dispatch('vatDeclaration/getVatDeclaration', vatDeclaration.id)
          .then(vatDeclaration => {
            currentVatDeclaration.value = vatDeclaration
            vatDeclarationsModalShow.value = true
          })
    }

    const submitValidatedVatDeclarationsLocal = (vatDeclaration) => {
        submitValidatedVatDeclaration(vatDeclaration)
            .then(response => {
              vatDeclarationsModalShow.value = false
              resetVatDeclaration()
            })
    }

    const resetVatDeclaration = () => {
      currentVatDeclaration.value = JSON.parse(JSON.stringify(store.getters['vatDeclaration/getEmptyVatDeclaration']))
    }

    const rowSelected = (rowSelected) => {
      selectedItems.value = rowSelected
    }

    const addPayment = (vatDeclarations) => {
      if (vatDeclarations.length) {
        let amount = 0
        let paymentRelations = []

        vatDeclarations.forEach(vd => {
          amount = parseFloat(vd.credit?vd.credit:vd.debit)
          paymentRelations.push({
            amount: parseFloat(vd.credit?vd.credit:vd.debit),
            vatDeclaration: vd
          })
        })

        currentPayment.value = {
          paymentMethod: { id: vatDeclarations[0].paymentMethod.id, name: i18n.t(vatDeclarations[0].paymentMethod.name) },
          date: (new Date()).toISOString().slice(0, 10),
          paymentRelations: paymentRelations,
          bankAccount: vatDeclarations[0].bankAccount,
          reference: capitalize(i18n.tc('vatDeclaration')) + ' : ' + moment(vatDeclarations[0].month).format('MMMM YYYY')
        }

        paymentModalShow.value = true
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            console.log(response)


            currentPayment.value = {}
            selectedItems.value = []
            openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetVatDeclaration()

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      vatDeclarationsModalShow,
      vatDeclarationsByUploadModalShow,
      newVatDeclarationFile,
      currentVatDeclaration,
      currentPayment,
      vatDeclarationModalTitle,
      paymentModalShow,

      // Computed
      vatDeclarations,
      vatDeclarationsContext,

      // Methods
      downloadVatDeclaration,
      removeVatDeclaration,
      showDetails,
      editVatDeclaration,
      submitValidatedVatDeclarationsLocal,
      rowSelected,
      addPayment,
      submitValidatedPaymentLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteVatDeclarationAlert (vatDeclaration) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theVatDeclaration') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeVatDeclaration(vatDeclaration)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>