import store from '@/store'

export const useVatDeclarations = () => {

  const submitValidatedVatDeclaration = (vatDeclaration) => {
    return new Promise((resolve, reject) => {
      if ('id' in vatDeclaration && vatDeclaration.id != null) {
        updateVatDeclaration(vatDeclaration)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewVatDeclaration(vatDeclaration)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewVatDeclaration = (vatDeclaration) => {
    return new Promise((resolve, reject) => {
      store.dispatch('vatDeclaration/addVatDeclaration', vatDeclaration)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateVatDeclaration = (vatDeclaration) => {
    return new Promise((resolve, reject) => {
      store.dispatch('vatDeclaration/updateVatDeclaration', vatDeclaration)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeVatDeclaration = (vatDeclarationDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [vatDeclarationDocument, 'vatDeclaration'])
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadVatDeclaration = (vatDeclaration) => {
    let link = document.createElement('a')
    link.href = vatDeclaration.document.url
    link.target = '_blank'
    link.download = vatDeclaration.document.name + vatDeclaration.document.extension
    link.click()
  }

  const removeVatDeclaration = (vatDeclaration) => {
    return new Promise((resolve, reject) => {
      store.dispatch('vatDeclaration/removeVatDeclaration', vatDeclaration)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedVatDeclaration,
    recognizeVatDeclaration,
    downloadVatDeclaration,
    removeVatDeclaration,
  }
}